import {
    Button,
    CircularProgress,
    FormControl,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Popover,
    Tooltip
} from '@material-ui/core';
import { Message, MessageOutlined } from '@material-ui/icons';
import { ToggleButton } from '@material-ui/lab';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import style from './Styles.module.scss';
import { Controller, useForm } from 'react-hook-form';
import { SHOW_SNACKBAR } from '../../../redux/Common/actionTypes';
import { useCookies } from 'react-cookie';
import { CreateBroadCastMessage } from '../../../redux/Users/actions';
import {
    CheckUserExists,
    CommonDispatcher
} from '../../../redux/Common/action';
import _ from 'lodash';
import EditorComponent from '../../InputComponents/Editor';
import { useLocation } from 'react-router-dom';

const BroadCastModal = ({
    CommonDispatcher,
    CreateBroadCastMessage,
    CheckUserExists,
    userIds,
    selectAll
}: any) => {
    const [filterValues, setFilterValues] = useState({});

    const [typeValue, setTypeValue] = useState('');
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        // Get the filter parameter
        const filterParam = params.get('filter');
        if (filterParam) {
            try {
                const decodedFilter = decodeURIComponent(filterParam);
                const filterObject = JSON.parse(decodedFilter);
                setFilterValues(filterObject);
            } catch (error) {
                console.error('Failed to parse filter:', error);
            }
        }

        // Get the type parameter
        const typeParam = params.get('type');
        if (typeParam) {
            setTypeValue(typeParam);
        }
    }, [location.search]);

    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [password, setPassword] = React.useState('');
    const [email, setEmail] = useState();
    const [description, setDescription] = useState('');

    const [isCheckUserLoading, setIsCheckUserLoading] = useState(false);
    const initialState = {};
    const [ownerData, setOwnerData] = useState<any>(initialState);
    const { control } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        shouldUnregister: false
    });
    const delayedQuery = useCallback(
        _.debounce((k) => handleSearch(k), 500),
        []
    );

    const doSearch = (e: any) => {
        const value = e.target.value;
        setEmail(email);
        if (value) {
            delayedQuery(value);
            setIsCheckUserLoading(true);
        } else {
            delayedQuery.cancel();
            setIsCheckUserLoading(false);
        }
    };

    const handleSearch = (email) => {
        CheckUserExists(token, { email }).then((d) => {
            setIsCheckUserLoading(false);
            if (d?.type === 'success') {
                setOwnerData(d?.response);
            } else if (d?.type === 'failed') {
                setOwnerData(initialState);
                CommonDispatcher(SHOW_SNACKBAR, {
                    isShowing: true,
                    message: 'User not found'
                });
            }
        });
    };

    const open = Boolean(anchorEl);
    const popUpId = open ? `popover` : undefined;

    const handleOpenPopup = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setPassword('');
    };

    const onDownload = (e = null) => {
        setIsLoading(true);
        let filter = {
            ...filterValues
        };
        if (typeValue?.length) {
            filter['type'] = typeValue;
        }
        CreateBroadCastMessage(token, {
            broadcasterUserId: ownerData?.user_id,
            message: description,
            filter,
            userIds,
            selectAll
        }).then((res) => {
            console.log(`🚀 ~ selectAll:`, res);
            if (res?.type === 'success') {
                setIsLoading(false);
                handleClose();
                CommonDispatcher(SHOW_SNACKBAR, {
                    isShowing: true,
                    message:
                        res.response.message ||
                        'Broadcast message sent successfully'
                });
            } else if (res?.type === 'failed' && res?.response?.data?.error) {
                setIsLoading(false);
                CommonDispatcher(SHOW_SNACKBAR, {
                    isShowing: true,
                    message:
                        res?.response?.data?.error ||
                        'Failed to send broadcast message',
                    hideAlert: true,
                    error: true
                });
            } else {
                setIsLoading(false);
                CommonDispatcher(SHOW_SNACKBAR, {
                    isShowing: true,
                    message: 'Failed to send broadcast message',
                    hideAlert: true,
                    error: true
                });
            }
        });
    };
    const handleDescriptionChange = (value: any) => {
        setDescription(value);
    };
    return (
        <div className={`${style.container}`}>
            <Tooltip
                title={'Send Broadcast Message'}
                aria-label={'Send Broadcast Message'}
                placement="bottom"
                arrow={true}
            >
                <ToggleButton
                    classes={{ selected: 'bgColorWhite' }}
                    style={{ borderRadius: '10px' }}
                    onClick={handleOpenPopup}
                    className="ml-3"
                    disabled={!selectAll && !userIds?.length}
                >
                    <Message className="colorPrimary" />
                </ToggleButton>
            </Tooltip>
            {open ? (
                <Popover
                    id={popUpId}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    classes={{ paper: 'max-w-500 w-100' }}
                >
                    {open ? (
                        <div className={`p-3 text-center`}>
                            <div className="mt-2 w-100">
                                <EditorComponent
                                    initialValue={description}
                                    placeholder="Enter your message here..."
                                    handleDescriptionChange={
                                        handleDescriptionChange
                                    }
                                    className="w-100"
                                />
                                <div className="mt-2">
                                    <FormControl variant="outlined">
                                        <InputLabel htmlFor="email">
                                            Email
                                        </InputLabel>
                                        <Controller
                                            name="email"
                                            control={control}
                                            render={() => (
                                                <OutlinedInput
                                                    required
                                                    fullWidth
                                                    autoFocus
                                                    name="email"
                                                    type="email"
                                                    label="Email"
                                                    id="email"
                                                    placeholder="john@email.com"
                                                    autoComplete="off"
                                                    onChange={(e) =>
                                                        doSearch(e)
                                                    }
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            {isCheckUserLoading ? (
                                                                <CircularProgress
                                                                    size="1.3rem"
                                                                    className="circular-progress-primary ml-0"
                                                                />
                                                            ) : null}
                                                        </InputAdornment>
                                                    }
                                                />
                                            )}
                                            defaultValue=""
                                        />
                                    </FormControl>
                                </div>
                            </div>
                            {/* <FormHelperText
                                error={true}
                                className={
                                    errors?.master_password
                                        ? 'visible'
                                        : 'invisible'
                                }
                            >
                                Master password is mandatory.
                            </FormHelperText> */}
                            <Button
                                variant="outlined"
                                color="inherit"
                                size="medium"
                                className="mt-3"
                                onClick={onDownload}
                                disabled={
                                    !Object.keys(ownerData)?.length ||
                                    !description?.length ||
                                    (description &&
                                        description === '<p><br></p>')
                                }
                            >
                                <MessageOutlined className={style.clearIcon} />
                                Send BroadCast Message
                                {isLoading ? (
                                    <CircularProgress
                                        size="1.3rem"
                                        className="circular-progress"
                                    />
                                ) : null}
                            </Button>
                        </div>
                    ) : null}
                </Popover>
            ) : null}
        </div>
    );
};

export default connect(null, {
    CommonDispatcher,
    CreateBroadCastMessage,
    CheckUserExists
})(BroadCastModal);
